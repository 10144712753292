// Generated by Framer (6597ba8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {k6j_rJULv: {hover: true}};

const cycleOrder = ["k6j_rJULv"];

const variantClassNames = {k6j_rJULv: "framer-v-1cty0ct"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {"k6j_rJULv-hover": {damping: 60, delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}, default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "k6j_rJULv", title: H6DLEnjCz = "Suzuki szervíz", image: BprsCg6Yo = {src: new URL("assets/512/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href} 4096w, ${new URL("assets/ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg", import.meta.url).href} 4640w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "k6j_rJULv", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (gestureVariant === "k6j_rJULv-hover") return true
return false
}

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-bpcam", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 2088, intrinsicWidth: 4640, pixelHeight: 2088, pixelWidth: 4640, ...toResponsiveImage(BprsCg6Yo)}} className={cx("framer-1cty0ct", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"k6j_rJULv"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"k6j_rJULv-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-s8xm2l"} layoutDependency={layoutDependency} layoutId={"Kdu9OIYeM"} style={{backgroundColor: "rgba(38, 38, 38, 0.25)"}} transition={transition} variants={{"k6j_rJULv-hover": {backgroundColor: "rgba(38, 38, 38, 0.85)"}}}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC03MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "25px", "--framer-font-weight": "700", "--framer-letter-spacing": "-1.9px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Juhhodály</motion.p></React.Fragment>} className={"framer-s0twwn"} fonts={["GF;Montserrat-700"]} layoutDependency={layoutDependency} layoutId={"IoHPcqSfL"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"center"} withExternalLayout/>)}</motion.div></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-bpcam [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bpcam * { box-sizing: border-box; }", ".framer-bpcam .framer-116m4ug { display: block; }", ".framer-bpcam .framer-1cty0ct { height: 200px; overflow: hidden; position: relative; width: 200px; }", ".framer-bpcam .framer-s8xm2l { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-bpcam .framer-1vo8xua { flex: none; height: 52px; left: calc(50.00000000000002% - 163px / 2); position: absolute; top: calc(50.00000000000002% - 52px / 2); white-space: pre-wrap; width: 163px; word-break: break-word; word-wrap: break-word; }", ".framer-bpcam .framer-1koe7ih { flex: none; height: 50px; left: calc(50.00000000000002% - 163px / 2); position: absolute; top: calc(50.00000000000002% - 50px / 2); white-space: pre-wrap; width: 163px; word-break: break-word; word-wrap: break-word; }", ".framer-bpcam .framer-s0twwn { flex: none; height: 57px; left: calc(50.00000000000002% - 149px / 2); position: absolute; top: calc(50.00000000000002% - 57px / 2); white-space: pre-wrap; width: 149px; word-break: break-word; word-wrap: break-word; }", ".framer-bpcam .framer-v-1cty0ct .framer-1cty0ct { cursor: pointer; }", ".framer-bpcam.framer-v-1cty0ct.hover .framer-1cty0ct { height: 254px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wQNAFIEtn":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"H6DLEnjCz":"title","BprsCg6Yo":"image"}
 */
const FramerZp7WJd4XG: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerZp7WJd4XG;

FramerZp7WJd4XG.displayName = "Fram3";

FramerZp7WJd4XG.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerZp7WJd4XG, {H6DLEnjCz: {defaultValue: "Suzuki szervíz", displayTextArea: false, title: "Title", type: ControlType.String}, BprsCg6Yo: {__defaultAssetReference: "data:framer/asset-reference,ghQNiIl6ZbRtRYF5uzc8ouu35I.jpg?originalFilename=36.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerZp7WJd4XG, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Zp7WJd4XG:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf", weight: "700"}])